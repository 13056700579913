const heading = () => {
  if (document.body.classList.contains('post_type-digital_display')) {
    const pageTitle = document.head.querySelector('title');
    const heading = document.querySelector('.header');
    const h1 = heading.querySelector('h1');
    const h3 = heading.querySelector('h3');
  
    const getProject = async () => {
      const post_id = new URLSearchParams(window.location.search).get('id');

      let post = {};
      await fetch(`https://skater.jnstn-dev.com/wp-json/wp/v2/posts/${post_id}/?per_page=100&acf_format=standard`, {
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }).then(resolve => resolve.json())
        .then(data => post = data);
  
      return post;
    }

    const renderHeading = async () => {
      let project = await getProject();
      h1.innerText = project.acf.thumbnail_title;
      h3.innerText = project.acf.client.post_title;

      // Render Page Title
      new Element({
        type: 'title',
        content: `JNSTN | ${project.acf.client.post_title} | Digital Display Units`,
        append: document.head,
      });
    }
    renderHeading()
  }
}