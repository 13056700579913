/* ------------------------------
COMPONENT: PROJECTS
------------------------------ */

const projects = () => {
  const parent = document.querySelectorAll('.projects');

  parent.forEach(cmpnt => {
    const grid = cmpnt.querySelector('.grid');

    const getProjects = async () => {
      let posts = [];
      await fetch(`${api}/posts/?per_page=100&acf_format=standard`, {
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }).then(resolve => resolve.json())
        .then(data => {
          posts = data.filter(post => post.acf.live_url.includes('showcase.jnstn.io'))
          grid.previousElementSibling.remove();
        });
  
      return posts;
    }
  
    const renderProjects = async () => {
      const allProjects = await getProjects();

      allProjects.forEach(project => {
        let live_url =
          window.location.hostname === 'localhost' ?
          project.acf.live_url.split('https://showcase.jnstn.io')[1] :
          project.acf.live_url;
        live_url = live_url.split("")[live_url.length - 1] !== '/' ? `${live_url}/` : live_url;

        const anchor = new Element({
          type: 'a',
          attributes: [
            ['href', `${live_url}?id=${project.id}`],
            ['data-project-type', project.acf.project_type],
          ],
          append: grid,
        })
  
        const thumbnail = new Element({
          type: 'div',
          classList: 'thumbnail',
          append: anchor
        })

        const thumbnail_image = new Element({
          type: 'div',
          classList: 'thumbnail-image',
          append: thumbnail
        })

        const image = new Element({
          type: 'img',
          src: project.acf.thumbnail_image,
          append: thumbnail_image
        })

        const thumbnail_title = new Element({
          type: 'div',
          classList: 'thumbnail-title',
          content: project.acf.thumbnail_title,
          append: thumbnail,
        })
      });
    }
    renderProjects()
  })

}