/* ------------------------------
COMPONENT: BACK BUTTON
------------------------------ */

const back = () => {
  if (window.location.hostname === 'localhost' && document.body.classList.contains('post_type-digital_display')) {
    new Element({
      type: 'a',
      content: '< Back to Projects',
      attributes: [
        ['href', '/'],
        ['style', 'display: inline-block; padding: 1em 1.5em;']
      ],
      append: {
        node: document.querySelector('.header'),
        insert: 'before',
      },
    })
  }
}