const api = 'https://skater.jnstn-dev.com/wp-json/wp/v2';

class Element {
  constructor(options) {
    this.attributes = options.attributes;
    this.append = options.append;
    this.classList = options.classList;
    this.content = options.content;
    this.id = options.id;
    this.type = options.type;
    this.src = options.src;

    this.el = null;

    this.createElement();
    this.applyID();
    this.applyClassList();
    this.applyContent()
    this.applyAttributes();
    this.applySrc();
    this.renderElement();

    return this.el;
  }

  createElement() {
    this.el = document.createElement(this.type);
  }

  applyID() {
    if (this.id) {
      this.el.id = this.id;
    }
  }

  applyClassList() {

    const renderClasses = (array) => {
      array.map((cls) => {
        this.el.classList.add(cls);
      });
    };

    if (this.classList) {
      if (typeof this.classList === 'string') {
        const allClassesArray = this.classList.split(' ');
        renderClasses(allClassesArray);
      }
      else {
        renderClasses(this.classList);
      }
    }
  }

  applyContent() {
    if (this.content) {
      this.el.innerHTML = this.content;
    }
  }

  applyAttributes() {
    if (this.attributes) {
      if (typeof this.attributes[0] === 'string') {
        this.el.setAttribute(this.attributes[0], this.attributes[1]);
      }
      else {
        this.attributes.map((attr) => {
          this.el.setAttribute(attr[0], attr[1]);
        });
      }
    }
  }

  applySrc() {
    if (this.src) {
      this.el.setAttribute('src', this.src);
    }
  }

  renderElement() {
    switch (this.append.insert) {
      case 'before':
        this.append.node.parentElement.insertBefore(this.el, this.append.node);
        break;
        
      case 'after':
        this.append.node.parentElement.insertBefore(this.el, this.append.node.nextSibling);
        break;
          
      default:
        this.append.appendChild(this.el);
        break;
    }
  }
};