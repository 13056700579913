/* ------------------------------
CORE
------------------------------ */

const site = () => {
  projects()
  back()
  units()
  heading();
}

document.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('load', site);
})