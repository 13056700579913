const units = () => {
  const parent = document.querySelectorAll('.units');

  parent.forEach(cmpnt => {
    if (cmpnt.hasAttribute('data-units')) {
      const unitNames = cmpnt.getAttribute('data-units').replace(/\s/g, '').split(',');
  
      unitNames.forEach(unit => {
        const dimensions = unit.split('-')[1].split('x');
        const width = dimensions[0];
        const height = dimensions[1];
  
        new Element({
          type: 'iframe',
          classList: unit,
          attributes: [
            ['src', `./${unit}/index.html`],
            ['width', width],
            ['height', height],
            ['frameborder', 0],
            ['style', `grid-area: ${unit}`]
          ],
          append: cmpnt,
        })
      })
    }
  })
}